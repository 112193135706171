import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Box, Typography, CircularProgress, Avatar, IconButton, Tooltip, InputLabel, FormControl, MenuItem, Select } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const ProfilePage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const { userName } = useParams();
    const [user, setUser] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [selectedMembership, setSelectedMembership] = useState(""); 
    const [selectedFile, setSelectedFile] = useState(null);
    const [memberships, setMemberships] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/users/profile`, {

                });

                const memberData = await axios.get(`${API_BASE_URL}/users/member/${userName}`);
                setProfileData(response.data);
                setProfilePicture(memberData.profilePicture);

                // Load memberships
                if (memberData.membershipList) {
                    setMemberships(memberData.membershipList);

                    // Set selected membership to the user's current membership, or default to ""
                    const userMembership = memberData.membershipId;
                    const membershipExists = memberData.membershipList.some(m => m.id === userMembership);

                    if (membershipExists) {
                        setSelectedMembership(userMembership);
                    } else {
                        setSelectedMembership(""); // Default to no selection if ID doesn't exist
                    }
                } else {
                    setMemberships([]); // Handle case where membership list is not available
                }
            } catch (err) {
                setError("Failed to load profile data.");
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfilePicture(reader.result); // Update profile picture state
            };
            reader.readAsDataURL(file);
            setSelectedFile(file);
        }
    };


    const handleSave = async () => {
        navigate('/members'); 
    };

    const handleCancel = () => {
        navigate('/members'); // Redirect to /members page
    };

    if (loading) {
        return <Container maxWidth="md" sx={{ mt: 8 }}><CircularProgress style={{ color: '#189AB4' }} /></Container>;
    }

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            <Typography variant="h2" gutterBottom>
                Edit your profile
            </Typography>

            <Box 
                sx={{
                    mt: 4,
                    p: 6,
                    boxShadow: 3,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 4, // Space between columns
                }}
            >
                {user && (
                    <>
                        {/* Left Column: User Picture and Membership Dropdown */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 0.1 }}>
                            {/* Profile Picture and Upload Icon Container */}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: '50px' }}>
                                {profilePicture ? (
                                    <Avatar
                                        src={profilePicture}
                                        alt="Profile picture"
                                        sx={{ width: 150, height: 150 }}
                                        style={{ border: '2px solid #189AB4' }}
                                    />
                                ) : (
                                    <Avatar
                                        sx={{ width: 150, height: 150 }}
                                        alt="User icon"
                                        style={{ border: '2px solid #189AB4' }}
                                    />
                                )}

                                <Tooltip title="Upload a new profile picture">
                                    <IconButton component="label" style={{ color: '#189AB4' }}>
                                        <EditIcon sx={{ fontSize: 30 }} />
                                        <input
                                            type="file"
                                            hidden
                                            accept="image/*"
                                            onChange={handleFileChange}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Box>

                            {/* Membership Dropdown */}
                            <FormControl fullWidth>
                                <InputLabel id="membership-label">Membership</InputLabel>
                                <Select
                                    labelId="membership-label"
                                    value={selectedMembership}
                                    onChange={(e) => setSelectedMembership(e.target.value)}
                                    label="Membership"
                                    style={{ marginBottom: '20px', height: '45px' }}
                                >
                                    <MenuItem value="">
                                        <em>Please select a membership</em>
                                    </MenuItem>
                                    {memberships.length > 0 ? (
                                        memberships.map((membership) => (
                                            <MenuItem key={membership.id} value={membership.id}>
                                                {membership.name} {/* Ensure this matches your data structure */}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" disabled>
                                            No memberships available
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Box>

                        {/* Right Column: User Details */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 0.9 }}>
                            <Box sx={{ display: 'flex', gap: 2, marginBottom: '20px' }}>
                                <TextField
                                    label="First name"
                                    value={user.firstName}
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                                    required
                                />
                                <TextField
                                    label="Last name"
                                    value={user.lastName}
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                                    required
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2, marginBottom: '20px' }}>
                                <TextField
                                    label="Display name"
                                    value={user.userName}
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, userName: e.target.value })}
                                    required
                                />
                                <TextField
                                    label="Email"
                                    value={user.email}
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                                    required
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2, marginBottom: '20px' }}>
                                <TextField
                                    label="Phone number"
                                    value={user.phoneNumber}
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })}
                                    required
                                />
                                <TextField
                                    label="Address"
                                    value={user.address}
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, address: e.target.value })}
                                    required
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2, marginBottom: '20px' }}>
                                <TextField
                                    label="City"
                                    value={user.city}
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, city: e.target.value })}
                                    required
                                />
                                <TextField
                                    label="Postal code"
                                    value={user.zipCode}
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, zipCode: e.target.value })}
                                    required
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2, marginBottom: '20px' }}>
                                <TextField
                                    label="State/Province"
                                    value={user.county}
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, county: e.target.value })}
                                    required
                                />
                                <TextField
                                    label="Country"
                                    value={user.country}
                                    fullWidth
                                    onChange={(e) => setUser({ ...user, country: e.target.value })}
                                    required
                                />
                            </Box>

                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<SaveIcon />}
                                    onClick={handleSave}
                                    style={{ color: 'white'}}
                                    fullWidth
                                >
                                    Save
                                </Button>

                                <Button
                                    variant="contained"
                                    color="error"
                                    startIcon={<CancelIcon />}
                                    onClick={handleCancel}
                                    fullWidth
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    );
};

export default ProfilePage;
