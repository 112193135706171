import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, TextField, Box, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useNavigate, useParams } from 'react-router-dom';

const MembershipsPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [memberships, setMemberships] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchMemberships = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/memberships`);
                setMemberships(response.data);
            } catch (err) {
                setError('Error fetching memberships. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchMemberships();
    }, []);


    const columns = [
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'description', headerName: 'Description', width: 400 },
        { field: 'price', headerName: 'Price', width: 150 },
        {
            field: 'courses',
            headerName: 'Available courses',
            width: 700,
            renderCell: (params) => (
                <Typography variant="body2">
                    {params.row.courses && params.row.courses.length > 0
                        ? params.row.courses.map(course => course.title).join(', ')
                        : 'No courses available'}
                </Typography>
            ),
        },
    ];

    const filteredMemberships = memberships.filter(membership => 
        (membership.name && membership.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (membership.description && membership.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const handleRowClick = (membership) => {
        const membershipId = membership.row.id;
        navigate(`/memberships/${membershipId}`); 
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 8 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h2" gutterBottom>
                    Memberships
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '35%' }}>
                    {/* Search input */}
                    <TextField
                        placeholder="Search"
                        variant="outlined"
                        sx={{ flexGrow: 1 }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}  // Update search term
                    />

                    <Button variant="contained" color="secondary" 
                            style={{ color: 'white', marginLeft: '20px' }} 
                            onClick={() => navigate('/membership/register')}>
                        <AddCircleOutlineOutlinedIcon sx={{ marginRight: '5px' }}/>
                        Create
                    </Button>
                </Box>
            </Box>

            <Box my={4}>
                {loading ? (
                        <CircularProgress style={{ color: '#189AB4'}} />
                    ) : (

                    <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
                        <DataGrid
                            rows={filteredMemberships}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 20]}
                            checkboxSelection
                            disableSelectionOnClick
                            onRowClick={handleRowClick}
                        />
                    </div>
                )}
            </Box>
        </Container>
    );


};

export default MembershipsPage;