import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Container, Typography, Card, CardMedia, CardContent, CircularProgress, Snackbar, Alert, Box, TextField, Button} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

const AuthorsPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [authors, setAuthors] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const loadAuthors = async () => {
            setLoading(true); 
            try {
                const response = await axios.get(`${API_BASE_URL}/authors`);
                setAuthors(response.data);
            } catch (err) {
                console.error('Error fetching authors:', err);
                setError('Failed to load authors. Please try again later.');
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }
        };

        loadAuthors();
    }, []);

    const handleRowClick = (author) => {
        const authorId = author.row.id;
        navigate(`/author/${authorId}`); 
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const columns = [
        { field: 'name', headerName: 'Name', width: 300 },
        { field: 'expertise', headerName: 'Expertise', width: 400},
        { field: 'description', headerName: 'Description', width: 600 },
    ];

    const filteredAuthors = authors.filter(author => 
        author.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        author.expertise.toLowerCase().includes(searchTerm.toLowerCase()) || 
        author.description.toLowerCase().includes(searchTerm.toLowerCase()) 
    );

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h2" gutterBottom>
                    Authors
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '35%' }}>
                    {/* Search input */}
                    <TextField
                        placeholder="Search"
                        variant="outlined"
                        sx={{ flexGrow: 1 }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}  // Update search term
                    />

                    <Button variant="contained" color="secondary" 
                            style={{ color: 'white', marginLeft: '20px' }} 
                            onClick={() => navigate('/author/register')}>
                        <AddCircleOutlineOutlinedIcon sx={{ marginRight: '5px' }}/>
                        Create
                    </Button>
                </Box>
            </Box>

            {error && (
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            )}

            <Box my={4}>
                {loading ? (
                    <CircularProgress style={{ color: '#189AB4' }} />
                ) : (

                    // <Box
                    //     display="flex"
                    //     flexWrap="wrap"
                    //     justifyContent="space-between"
                    //     sx={{ gap: 2 }}
                    // >
                    //     {filteredAuthors.map((author, index) => (
                    //         <Box 
                    //             key={`${author.id}-${index}`} 
                    //             width={{ xs: '100%', sm: '48%', md: '30%', lg: '22%' }} // Responsive width
                    //             onClick={() => navigate(`/author/${author.id}`)}
                    //             sx={{ cursor: 'pointer' }}
                    //         >
                    //             <Card>
                    //                 <CardMedia
                    //                     component="img"
                    //                     height="140"
                    //                     image={author.pictureUrl} // Thumbnail URL
                    //                     alt={author.title}
                    //                 />
                    //                 <CardContent>
                    //                     <Typography gutterBottom variant="h5" component="div">
                    //                         {author.title}
                    //                     </Typography>
                    //                     <Typography variant="body2" color="text.secondary">
                    //                         {author.description}
                    //                     </Typography>
                    //                 </CardContent>
                    //             </Card>
                    //         </Box>
                    //     ))}
                    // </Box>

                    <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
                        <DataGrid
                            rows={filteredAuthors}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 20]}
                            checkboxSelection
                            disableSelectionOnClick
                            onRowClick={handleRowClick}
                        />
                    </div>
                )}

            </Box>
        </Container>
    );
};

export default AuthorsPage;