import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Box, Typography } from '@mui/material';

const RequestPasswordResetPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Handle email change
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!email) {
            setError('Email address is required');
            return;
        }

        setLoading(true);
        setError('');
        setMessage('');

        try {
            // Send request to the backend API
            const response = await axios.post(`${API_BASE_URL}/account/request-password-reset`, {
                email,
            });

            // Success response
            setMessage(response.data.message);
        } catch (err) {
            // Error response
            setError(err.response ? err.response.data.message : 'An error occurred, please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container sx={{ mt: 8, maxWidth: '600px !important' }}>
            <Box 
                sx={{
                mt: 4,
                p: 6,
                boxShadow: 3,
                borderRadius: 2,
                }}
            > 
                <Typography variant="h2" align='center' gutterBottom sx={{ marginBottom: '50px', fontWeight: 'bold'}}>
                    Request password reset
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        required
                        sx={{ marginBottom: '20px' }}
                    />
                    {error && (
                        <Typography color="error" sx={{ marginBottom: '20px' }}>
                            {error}
                        </Typography>
                    )}
                    {message && (
                        <Typography color="success" sx={{ marginBottom: '20px' }}>
                            {message}
                        </Typography>
                    )}
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        fullWidth
                        disabled={loading}
                        style={{ color: 'white' }}
                    >
                        {loading ? 'Sending...' : 'Send Reset Link'}
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default RequestPasswordResetPage;
