import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Snackbar, Alert, Box, TextField, Button, IconButton, 
    Dialog, DialogActions,  DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import VideoPlayer from "../../../components/VideoPlayer";
import { BlockBlobClient } from "@azure/storage-blob";

const LessonDetailPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const { id } = useParams();
    const [lesson, setLesson] = useState({ resources: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [videoName, setVideoName] = useState(null);
    const [selectedResourceFile, setSelectedResourceFile] = useState(null);
    const [resourceTitle, setResourceTitle] = useState('');
    const [newResourceFiles, setNewResourceFiles] = useState([]); // New files to be uploaded
    const [existingResources, setExistingResources] = useState([]); // Existing resources
    const [uploadProgress, setUploadProgress] = useState(0);
    const [progressModalOpen, setProgressModalOpen] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        const fetchLesson = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/lessons/lesson/${id}`);
                setLesson({
                    ...response.data,
                    courseId: response.data.courseId,
                });
                setExistingResources(response.data.resources || []);
            } catch (err) {
                console.error('Error fetching lesson:', err);
                setError('Failed to load lesson details. Please refresh the page and try again.');
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }
        };
        fetchLesson();
    }, [id]);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLesson({ ...lesson, [name]: value });
    };

    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        if (!file) return;
    
        if (type === "thumbnail") {
            setSelectedFile(file); // Store the file in state for later use
            const reader = new FileReader();
            reader.onloadend = () => setThumbnailUrl(reader.result); // Generate a preview
            reader.readAsDataURL(file);
        } else if (type === "video") {
            setFile(file); // Store the video file in state for later use
            setVideoName(file.name); // Optionally display the video name
        }
        else if (type === "resource") {
            setSelectedResourceFile(file); // Store the resource file in state
            setNewResourceFiles((prevFiles) => [...prevFiles, file]);
            setResourceTitle(file.name); // Optionally set the resource title to the file name
        }
    };

    const handleAddResource = async () => {
        if (!selectedResourceFile || !resourceTitle) return;
    
        const formData = new FormData();
        formData.append('file', selectedResourceFile);
        formData.append('title', resourceTitle);
    
        try {
            const response = await axios.post(
                `${API_BASE_URL}/resources/lesson/${id}/upload`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
    
            const newResource = response.data;
    
            // Update the lesson's resources
            setLesson((prevLesson) => ({
                ...prevLesson,
                resources: [...prevLesson.resources, newResource],
            }));
    
            setOpenModal(false); // Close the modal
        } catch (error) {
            console.error('Error uploading resource:', error);
            setError('Failed to upload resource. Please try again.');
            setSnackbarOpen(true);
        }
    };

    const handleRemoveResource = (resourceId) => {
        setExistingResources((prev) =>
            prev.filter((resource) => resource.id !== resourceId)
        );
    };
    
    const handleRemoveNewResource = (index) => {
        setNewResourceFiles((prevFiles) =>
            prevFiles.filter((_, i) => i !== index)
        );
    };

    const uploadFileToAzure = async (file, type) => {
        const response = await fetch(`${API_BASE_URL}/upload/generate-sas-token?fileName=${file.name}`);
        const { sasUrl } = await response.json();
    
        const blobClient = new BlockBlobClient(sasUrl);
    
        try {
            await blobClient.uploadBrowserData(file, {
                blobHTTPHeaders: { blobContentType: file.type },
                onProgress: (progress) => {
                    const percentage = Math.round((progress.loadedBytes / file.size) * 100);
                    setUploadProgress(percentage); // Update progress
                },
            });
    
            return sasUrl.split("?")[0]; // Return uploaded URL
        } catch (error) {
            console.error(`Error uploading ${type}:`, error);
            throw new Error(`Failed to upload ${type}`);
        }
    };

    const handleSave = async () => {
        setProgressModalOpen(true);
        try {
            let thumbnailUrl = lesson.thumbnailUrl;
            let videoUrl = lesson.videoUrl;

            // Upload Thumbnail if Selected
            if (selectedFile) {
                const thumbnailResponse = await uploadFileToAzure(selectedFile, "thumbnail");
                thumbnailUrl = thumbnailResponse; // Save the uploaded URL
            }

            // Upload Video if Selected
            if (file) {
                const videoResponse = await uploadFileToAzure(file, "video");
                videoUrl = videoResponse; // Save the uploaded URL
            }

            // Include resource URLs
            const resourceUrls = [];
            for (const file of newResourceFiles) {
                const resourceUrl = await uploadFileToAzure(file, "resource");
                resourceUrls.push({ name: file.name, url: resourceUrl });
            }

            const formData = new FormData();
            formData.append('title', lesson.title);
            formData.append('description', lesson.description || '');
            formData.append('courseId', lesson.courseId);
            // Append either file or URL for thumbnail
            if (selectedFile) {
                formData.append('thumbnailFile', selectedFile);
            } else if (lesson.thumbnailUrl) {
                formData.append('thumbnailUrl', lesson.thumbnailUrl);
            }

            // Append either file or URL for video
            if (file) {
                formData.append('videoFile', file);
            } else if (lesson.videoUrl) {
                formData.append('videoFileUrl', lesson.videoUrl);
            }

            formData.append('resourcesJson', JSON.stringify([...existingResources, ...resourceUrls]));
        
            await axios.put(`${API_BASE_URL}/lessons/lesson/${id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setProgressModalOpen(false);
            navigate('/lessons');
        } catch (err) {
            console.error('Error saving lesson:', err);
            setError('Failed to save lesson. Please try again.');
            setSnackbarOpen(true);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${API_BASE_URL}/lessons/lesson/${id}`);
            navigate('/lessons');
        } catch (error) {
            console.error('Error deleting lesson:', error);
            setError('Failed to delete lesson. Please try again.');
            setSnackbarOpen(true);
        }
    };

    const handleCancel = () => {
        navigate('/lessons');
    };

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            {error && (
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            )}
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h2" gutterBottom>
                    Edit lesson details
                </Typography>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DeleteOutlineOutlinedIcon />}
                    onClick={() => setConfirmDelete(true)}
                    style={{ color: 'white'}}
                >
                    Delete Lesson
                </Button>

                <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to delete this lesson?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDelete} color="secondary" variant="outlined" style={{color: '#189AB4'}}>
                            Delete
                        </Button>
                        <Button onClick={() => setConfirmDelete(false)} color="error" variant="outlined" style={{color: '#05445E'}}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

            <Box
                sx={{
                    mt: 4,
                    p: 6,
                    boxShadow: 3,
                    borderRadius: 2,
                }}
            >
                {loading ? (
                    <CircularProgress style={{ color: '#189AB4' }} />
                ) : (
                    <>
                        {lesson && (
                            <>
                                <Box sx={{ display: 'flex', gap: 2, marginBottom: '30px' }}>
                                    <TextField
                                        name="title"
                                        label="Title"
                                        value={lesson.title || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        required
                                        sx={{ marginBottom: '20px' }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
                                    <TextField
                                        label="Description"
                                        name="description"
                                        value={lesson.description || ''}
                                        onChange={handleInputChange}
                                        required
                                        fullWidth
                                        multiline
                                        rows={5}
                                    />
                                </Box>

                                <Typography 
                                    variant="h6" 
                                    sx={{ marginBottom: 2, color: 'primary.main' }}
                                >
                                        Thumbnail
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: '50px' }}>
                                    {thumbnailUrl ? (
                                        <>
                                        <Box
                                            component="img"
                                            src={thumbnailUrl}
                                            alt={lesson.title}
                                            sx={{
                                                width: "50%",
                                                height: "auto",
                                                borderRadius: 2,
                                            }}
                                            style={{ border: '2px solid #189AB4' }}
                                        />
                                        </>
                                    ) : (
                                        <Box
                                            component="img"
                                            src={lesson.thumbnailUrl}
                                            alt={lesson.title}
                                            sx={{
                                                width: "50%",
                                                height: "auto",
                                                borderRadius: 2,
                                            }}
                                            style={{ border: '2px solid #189AB4' }}
                                        />
                                    )}

                                    <Tooltip title="Upload a thumbnail">
                                        <IconButton component="label" style={{ color: '#189AB4' }}>
                                            <EditIcon sx={{ fontSize: 30 }} />
                                            <input
                                                type="file"
                                                hidden
                                                accept="image/*"
                                                onChange={(e) => handleFileChange(e, "thumbnail")}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                {/* Preview Video Upload */}
                                <Typography 
                                    variant="h6" 
                                    sx={{ marginBottom: 2, color: 'primary.main' }}
                                >
                                    Preview Video
                                </Typography>
                                {/* Display the selected video name if uploading */}
                                {lesson.videoUrl && (
                                    <Typography variant="h7" sx={{ marginBottom: 4, color: 'text.secondary' }}>
                                        Video url: {lesson.videoUrl}
                                    </Typography>
                                )}
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, marginBottom: "30px" }}>
                                    {/* If there's a video URL, display the video and an EditIcon */}
                                    {lesson.videoUrl ? (
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                            <VideoPlayer blobUrl={lesson.videoUrl} sx={{ width: '50%'}}/>
                                            <Tooltip title="Edit or Replace Video">
                                                <IconButton component="label" style={{ color: '#189AB4' }}>
                                                    <EditIcon sx={{ fontSize: 30 }} />
                                                    <input
                                                        type="file"
                                                        hidden
                                                        accept="video/*"
                                                        onChange={(e) => handleFileChange(e, "video")}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    ) : (
                                        // Upload video button if no video exists
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                            <input
                                                type="file"
                                                accept="video/*"
                                                onChange={(e) => handleFileChange(e, "video")}
                                                style={{ display: "none" }}
                                                id="preview-video-file"
                                            />
                                            <label htmlFor="preview-video-file">
                                                <Button variant="contained" color="secondary" component="span" sx={{ color: '#fff' }}>
                                                    {lesson.videoUrl ? "Change Video" : "Upload Video"}
                                                </Button>
                                            </label>
                                        </Box>
                                    )}
                                </Box>

                                <Dialog open={progressModalOpen} onClose={() => setProgressModalOpen(false)}>
                                    <DialogTitle>Uploading Files</DialogTitle>
                                    <DialogContent>
                                        <Typography variant="body1" align="center" gutterBottom>
                                            Uploading... {uploadProgress}%
                                        </Typography>
                                        <Box sx={{ width: '100%' }}>
                                            <CircularProgress variant="determinate" value={uploadProgress} />
                                        </Box>
                                    </DialogContent>
                                </Dialog>

                                <Box>
                                    <Typography variant="h6" sx={{ mt: 4 }}>Resources</Typography>
                                    {existingResources.length > 0 ? (
                                        existingResources.map((resource, index) => (
                                            <Box
                                                key={resource.id}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    mb: 2,
                                                }}
                                            >
                                                <TextField
                                                    value={resource.name || ""}
                                                    onChange={(e) => {
                                                        const updatedResources = [...existingResources];
                                                        updatedResources[index].name = e.target.value;
                                                        setExistingResources(updatedResources);
                                                    }}
                                                    label=""
                                                    fullWidth
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    href={resource.url}
                                                    target="_blank"
                                                    sx={{ ml: 2, color: "#fff" }}
                                                    startIcon={<DownloadIcon />}
                                                >
                                                    View
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => handleRemoveResource(resource.id)}
                                                    sx={{ ml: 2 }}
                                                    startIcon={<DeleteOutlineOutlinedIcon />}
                                                >
                                                    Remove
                                                </Button>
                                            </Box>
                                        ))
                                    ) : (
                                        <Typography variant="body2" color="text.secondary">
                                            No resources available.
                                        </Typography>
                                    )}
                                </Box>


                                {/* Add Resource Section */}
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 4 }}>

                                    {/* File Upload Input */}
                                    <input
                                        type="file"
                                        accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg"
                                        onChange={(e) => handleFileChange(e, "resource")}
                                        style={{ display: "none" }}
                                        id="resource-file-upload"
                                    />
                                    <label htmlFor="resource-file-upload">
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            //onClick={handleOpenModal}
                                            //onClick={handleAddResource} 
                                            startIcon={<AddCircleOutlineOutlinedIcon/>}
                                            style={{ color: 'white' }}>
                                            Upload File
                                        </Button>
                                    </label>

                                    {/* Display Selected Resources */}
                                    {newResourceFiles.length > 0 && (
                                        <Box>
                                            {newResourceFiles.map((file, index) => (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        mb: 1,
                                                    }}
                                                >
                                                    <Typography>{file.name}</Typography>
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        onClick={() => handleRemoveNewResource(index)}
                                                    >
                                                        Remove
                                                    </Button>
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                </Box>


                                {/* 
                                <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                                    <DialogTitle>Upload a Resource</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            label="Resource Title"
                                            fullWidth
                                            required
                                            value={resourceTitle}
                                            onChange={(e) => setResourceTitle(e.target.value)}
                                        />
                                        <input
                                            type="file"
                                            accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg"
                                            onChange={(e) => handleFileChange(e, "resource")}
                                            style={{ display: "none" }}
                                            id="resource-file-upload"
                                        />
                                        <label htmlFor="resource-file-upload">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                component="span"
                                                fullWidth
                                                sx={{ mt: 2 }}
                                                style={{ color: 'white' }}
                                            >
                                                Upload File
                                            </Button>
                                        </label>

                                        {newResourceFiles.length > 0 && (
                                            <Box>
                                                {newResourceFiles.map((file, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            mb: 1,
                                                        }}
                                                    >
                                                        <Typography>{file.name}</Typography>
                                                        <Button
                                                            variant="outlined"
                                                            color="error"
                                                            onClick={() => handleRemoveNewResource(index)}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button disabled={!resourceTitle || !selectedResourceFile} onClick={handleAddResource} color="secondary" startIcon={<AddCircleOutlineOutlinedIcon />}>
                                            Add Resource
                                        </Button>
                                        <Button onClick={handleCloseModal} color="error" startIcon={<CancelIcon />}>
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </Dialog> */}

                                
                            </>
                        )}
                    </>
                )}

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                        style={{ color: 'white' }}
                        fullWidth
                    >
                        Save
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<CancelIcon />}
                        onClick={handleCancel}
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default LessonDetailPage;