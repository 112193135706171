import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, Typography, Card, CardMedia, CardContent, CircularProgress, Snackbar, Alert, Box, IconButton, Button, AppBar, Toolbar, Tabs, Tab } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import 'swiper/css';
import 'swiper/css/navigation'; // For navigation arrows
import 'swiper/css/pagination'; // If you're using pagination


const MembersCoursesPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [courses, setCourses] = useState([]);
    const [trendingCourses, setTrendingCourses] = useState([]);
    const [advancedCourses, setAdvancedCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [activeTab, setActiveTab] = useState(0);

    const [isBeginning, setIsBeginning] = useState({});
    const [isEnd, setIsEnd] = useState({});
    const swiperRef = useRef(null); // Reference to Swiper instance

    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        const loadCourses = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${API_BASE_URL}/courses/courses`);
                const allCourses = response.data;
                setCourses(allCourses);
                
                // You can call loadTrendingCourses here, or load all at once
                await loadTrendingCourses(); // To load trending courses separately if needed
                await loadAdvancedCourses();
            } catch (err) {
                console.error('Error fetching courses:', err);
                setError('Failed to load courses. Please try again later.');
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }
        };
    
        const loadTrendingCourses = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/courses/trending`);
                setTrendingCourses(response.data);
            } catch (err) {
                console.error('Error fetching trending courses:', err);
            }
        };

        const loadAdvancedCourses = async () => {
            try{
                const response = await axios.get(`${API_BASE_URL}/courses/without-memberships`);
                setAdvancedCourses(response.data);
            }
            catch (err) {
                console.error('Error fetching advanced courses:', err);
            }
        };
    
        loadCourses();
    }, []);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleSwiperUpdate = (swiper, category) => {
        // Update swiper reference when swiper is initialized
        swiperRef[category] = swiper;
        
        // Update arrow visibility when slide changes
        setIsBeginning((prev) => ({
            ...prev,
            [`isBeginning_${category}`]: swiper.isBeginning,
        }));
        setIsEnd((prev) => ({
            ...prev,
            [`isEnd_${category}`]: swiper.isEnd,
        }));
    };
    
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    // Organize courses by category
    const categories = {
        Money: courses.filter(course => course.category === 'money'),
        Investments: courses.filter(course => course.category === 'investments'),
        Personaldevelopment: courses.filter(course => course.category === 'personal-development')
        // Add other categories as needed
    };

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            {/* AppBar with category links */}
            <AppBar position="sticky" color="default">
                <Toolbar sx={{ borderBottom: '1px solid #ddd' }}>
                    <Tabs value={activeTab} onChange={handleTabChange} sx={{ width: '100%', justifyContent: 'space-between' }}>
                        <Tab label="Your Journey" component={ScrollLink} to="your-journey" offset={-100} smooth duration={500} spy activeClass="active-tab" />
                        <Tab label="Trending" component={ScrollLink} to="trending" offset={-100} smooth duration={500} spy activeClass="active-tab" />
                        
                        {Object.keys(categories).map((category, index) => (
                            <Tab
                                key={index}
                                label={category}
                                component={ScrollLink}
                                to={category.toLowerCase()}
                                offset={-100}
                                smooth
                                duration={500}
                                spy
                                activeClass="active-tab"
                            />
                        ))}

                        <Tab label="Advanced" component={ScrollLink} to="advanced" offset={-100} smooth duration={500} spy activeClass="active-tab" />
                        <Tab label="Coming Soon" component={ScrollLink} to="coming-soon" offset={-100} smooth duration={500} spy activeClass="active-tab" />

                    </Tabs>
                </Toolbar>
            </AppBar>


            {error && (
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            )}

            {/* Category Sections */}
            <Box sx={{ mt: 4 }}>
                {loading ? (
                    <CircularProgress style={{ color: '#189AB4' }} />
                ) : (
                    <>
                        <Box id="your-journey" sx={{ mt: 6 }}>
                            <Typography variant="h4" gutterBottom>Your Journey</Typography>
                            {/* Add Your Journey content here */}
                        </Box>

                        {trendingCourses?.length > 0 && (
                            <Box id="trending" sx={{ mt: 6 }}>
                                <Typography variant="h4" gutterBottom>Trending</Typography>
                                <Box 
                                    sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', 
                                        gap: 2, width: '100%' }}
                                >
                                    {trendingCourses.map((course, index) => (
                                        <Box 
                                            key={course.id} 
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '60%',
                                                mb: 2,
                                                [theme.breakpoints.up('640')]: {
                                                    width: '45%',
                                                },
                                                [theme.breakpoints.up('768')]: {
                                                    width: '40%',
                                                },
                                                [theme.breakpoints.up('1024')]: {
                                                    width: '30%', 
                                                },
                                            }}
                                        >
                                            {/* Rank Number */}
                                            <Typography 
                                                variant="h5" 
                                                fontWeight="bold" 
                                                sx={{ width: 30, color: '#189AB4' }} // Orange for rank numbers
                                            >
                                                    {index + 1}
                                                </Typography>
            
                                            {/* Course Image */}
                                            <Box
                                                component="img"
                                                src={course.thumbnailUrl}
                                                alt={course.title}
                                                sx={{
                                                    width: 60,
                                                    height: 60,
                                                    borderRadius: 1,
                                                    mx: 2,
                                                    objectFit: 'cover',
                                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                                }}
                                            />
            
                                            {/* Course Details */}
                                            <Box sx={{ flex: 1, minWidth: 0 }}>
                                                <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {course.title}
                                                    : Lorem Ipsum is simply text 
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {course.author}
                                                </Typography>
                                            </Box>
                                        
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )}

                        <Box sx={{ mt: 6, width: '100%' }}>
                            <Typography variant="h2" gutterBottom sx={{color: '#189AB4'}}>Courses by Categories</Typography>
                            Embark on expert-led transformation programs
                        </Box>
                        
                        {Object.keys(categories).map((category, index) => (
                            <Box id={category.toLowerCase()} key={category} sx={{ mt: 6, position: 'relative', width: '100%' }}>
                                
                                
                                <Typography variant="h4" gutterBottom>
                                    {category}
                                </Typography>

                                {/* Left Arrow */}
                                {!isBeginning[`isBeginning_${category}`] && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '45%',
                                            left: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef[category]?.slidePrev()}
                                    >
                                        <ArrowBackIos sx={{ fontSize: 20, paddingLeft: '5px' }} />
                                    </IconButton>
                                )}

                                {/* Right Arrow */}
                                {!isEnd[`isEnd_${category}`] && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '45%',
                                            right: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef[category]?.slideNext()}
                                    >
                                        <ArrowForwardIos sx={{ fontSize: 20, padding: '2px' }} />
                                    </IconButton>
                                )}

                                <Swiper
                                    spaceBetween={25} // Space between slides
                                    slidesPerView={4} // Show 3 slides at a time
                                    breakpoints={{
                                        640: { slidesPerView: 2 }, // 1 slide for small screens
                                        768: { slidesPerView: 3 }, // 2 slides for medium screens
                                        1024: { slidesPerView: 4 }, // 3 slides for large screens
                                    }}
                                    onSwiper={(swiper) => handleSwiperUpdate(swiper, category)}
                                    onSlideChange={(swiper) => handleSwiperUpdate(swiper, category)}
                                    onReachBeginning={() => setIsBeginning(prev => ({ ...prev, [`isBeginning_${category}`]: true }))}
                                    onReachEnd={() => setIsEnd(prev => ({ ...prev, [`isEnd_${category}`]: true }))}
                                    onFromEdge={() => {
                                        setIsBeginning(prev => ({ ...prev, [`isBeginning_${category}`]: false }));
                                        setIsEnd(prev => ({ ...prev, [`isEnd_${category}`]: false }));
                                    }}
                                    loop={false}
                                    modules={[Navigation]}
                                >
                                    {categories[category].map((course, index) => (
                                        <SwiperSlide 
                                            key={`${course.id}-${index}`} 
                                            onClick={() => navigate(`/mycourses/${course.id}`)}
                                        >
                                            {/* Course Image */}
                                            <Box
                                                component="img"
                                                src={course.thumbnailUrl}
                                                alt={course.title}
                                                sx={{
                                                    width: '100%',
                                                    height: 200,
                                                    objectFit: 'cover',
                                                    borderRadius: 2,
                                                }}
                                            />

                                            {/* Course Title */}
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight="bold"
                                                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                            >
                                                {course.title}
                                                : Lorem Ipsum is simply text
                                            </Typography>

                                            {/* Course Author */}
                                            <Typography
                                                variant="body2"
                                                color="#189AB4"
                                                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                            >
                                                {course.author}
                                            </Typography>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Box>
                        ))}

                        {/* Advanced Courses Carousel */}
                        {advancedCourses?.length > 0 && (
                            <Box id="advanced" sx={{ mt: 6, position: 'relative', width: '100%' }}>
                                <Typography variant="h4" gutterBottom>Advanced courses</Typography>

                                {/* Left Arrow for Advanced */}
                                {!isBeginning['isBeginning_advanced'] && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '45%',
                                            left: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef['advanced']?.slidePrev()}
                                    >
                                        <ArrowBackIos sx={{ fontSize: 20, paddingLeft: '5px' }} />
                                    </IconButton>
                                )}

                                {/* Right Arrow for Advanced */}
                                {!isEnd['isEnd_advanced'] && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '45%',
                                            right: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef['advanced']?.slideNext()}
                                    >
                                        <ArrowForwardIos sx={{ fontSize: 20, padding: '2px' }} />
                                    </IconButton>
                                )}

                                <Swiper
                                    spaceBetween={25}
                                    slidesPerView={4}
                                    breakpoints={{
                                        640: { slidesPerView: 2 },
                                        768: { slidesPerView: 3 },
                                        1024: { slidesPerView: 4 },
                                    }}
                                    onSwiper={(swiper) => handleSwiperUpdate(swiper, 'advanced')}
                                    onSlideChange={(swiper) => handleSwiperUpdate(swiper, 'advanced')}
                                    onReachBeginning={() => setIsBeginning(prev => ({ ...prev, 'isBeginning_advanced': true }))}
                                    onReachEnd={() => setIsEnd(prev => ({ ...prev, 'isEnd_advanced': true }))}
                                    onFromEdge={() => {
                                        setIsBeginning(prev => ({ ...prev, 'isBeginning_advanced': false }));
                                        setIsEnd(prev => ({ ...prev, 'isEnd_advanced': false }));
                                    }}
                                    loop={false}
                                    modules={[Navigation]}
                                    ref={(swiper) => swiperRef['advanced'] = swiper}
                                >
                                    {advancedCourses.map((course, index) => (
                                        <SwiperSlide
                                            key={course.id}
                                            onClick={() => navigate(`/course/${course.id}`)}
                                        >
                                            {/* Course Content */}
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                {/* Course Image */}
                                                <Box
                                                    component="img"
                                                    src={course.thumbnailUrl}
                                                    alt={course.title}
                                                    sx={{
                                                        width: '100%',
                                                        height: 200,
                                                        objectFit: 'cover',
                                                        borderRadius: 2,
                                                        position: 'relative', // Ensure text can overlay properly
                                                    }}
                                                />
                                                {/* LockOpenIcon in the center of the image */}
                                                <LockIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '47%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        fontSize: '100px !important',   // Adjust icon size
                                                        color: 'rgba(0, 0, 0, 0.5)',  // Adjust color
                                                        zIndex: 5,  // Make sure it sits on top of the image
                                                    }}
                                                />
                                                {/* Course Title */}
                                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontWeight="bold"
                                                        sx={{
                                                            whiteSpace: 'normal', // Allow text to wrap to next line if necessary
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis', // Apply ellipsis if text is too long
                                                            lineHeight: '1.5', // Adjust line height for better readability
                                                            display: 'block', // Ensure it is a block element for correct sizing
                                                            padding: '0 8px', // Add some padding for better presentation
                                                        }}
                                                    >
                                                        {course.title}
                                                    </Typography>
                                                </Box>
                                                {/* Course Author */}
                                                <Typography
                                                    variant="body2"
                                                    color="#189AB4"
                                                    sx={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {course.author}
                                                </Typography>
                                            </Box>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Box>
                        )}

                        <Box id="coming-soon" sx={{ mt: 6 }}>
                            <Typography variant="h4" gutterBottom>Coming Soon</Typography>
                            {/* Add Coming Soon content here */}
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    );
};

export default MembersCoursesPage;