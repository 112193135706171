import React, {useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { AppBar, Toolbar, Button, Box, Menu,
//     MenuItem, IconButton } from '@mui/material';

import { AppBar, Toolbar, Button, IconButton, Box, IconButton as MuiIconButton,
        Menu, MenuItem, Avatar, Badge, Typography, Drawer, List, ListItem, ListItemText} from '@mui/material';
import { Link } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import logo from '../assets/images/logos/Logo-01.png';

import { Menu as MenuIcon, AccountCircle, Language as LanguageIcon } from '@mui/icons-material';


// export const isAuthenticated = () => {
//     return !!localStorage.getItem('authToken'); // Or your preferred method of checking auth status
// };

export const isAuthenticated = () => {
    // Check if token exists in localStorage or sessionStorage based on the user's choice (rememberMe)
    const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken');
    const tokenExpiration = localStorage.getItem('tokenExpiration') || sessionStorage.getItem('tokenExpiration');
    
    // Check if the token exists and is not expired
    if (token && tokenExpiration) {
      const currentTime = new Date();
      const expirationTime = new Date(tokenExpiration);
      return currentTime < expirationTime; // Return true if token is still valid
    }
    
    return false; // Return false if no token or if token is expired
};
  
  
  export const getRole = () => {
    // Retrieve the user role from localStorage or sessionStorage depending on how it was stored
    return localStorage.getItem('userRole') || sessionStorage.getItem('userRole');
  };
  

const Navbar = () => {

    const role = getRole();
    console.log(isAuthenticated(), role);

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const navigate = useNavigate();

    // Toggle mobile drawer
    const toggleMobileMenu = (open) => () => {
        setMobileMenuOpen(open);
    };

    // Mock user data
    const user = {
        isAuthenticated: isAuthenticated(), 
        profilePicture: null,  // URL for profile picture, null if no picture
        role: role 
    };

    console.log(user);

    // Mock language state
    const [language, setLanguage] = useState('EN');

    const changeLanguage = (lang) => {
        setLanguage(lang);
        handleLanguageMenuClose();
    };

    const handleNotificationsMenuOpen = (event) => setNotificationsAnchorEl(event.currentTarget);
    const handleNotificationsMenuClose = () => setNotificationsAnchorEl(null);
    const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleProfileMenuClose = () => setAnchorEl(null);
    const handleLanguageMenuOpen = (event) => setLanguageAnchorEl(event.currentTarget);
    const handleLanguageMenuClose = () => setLanguageAnchorEl(null);


    const handleViewProfile = () => {
        navigate('/profile');
    };

    const handleLogout = () => {
        // Clear token and user data from local storage or session storage
        localStorage.removeItem('authToken');
        localStorage.removeItem('userRole');
    
        // Redirect to login page or home page
        window.location.href = '/login';
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
    const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);

    return(
        <AppBar position="static">
            <Toolbar sx={{ backgroundColor: '#f7f7f7', margin: 0, justifyContent: 'space-between' }}>
                
                <Box 
                    component="img" 
                    src={logo} 
                    alt="Logo"
                    sx={{ 
                        height: { xs: 25, sm: 45 }, 
                        mr: 2, 
                        ml: { xs: 0, sm: 8 }
                    }} 
                />

                {/* Hamburger Menu Icon */}
                <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleMobileMenu(false)}
                    sx={{ display: { xs: 'block', sm: 'none' } }} // Show only on mobile
                    >
                    <MenuIcon />
                </IconButton>

                {/* Drawer for Mobile Menu */}
                <Drawer anchor="right" open={mobileMenuOpen} onClose={toggleMobileMenu(false)}>
                    <Box
                        sx={{
                        width: 250,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        backgroundColor: '#fff',
                        }}
                        role="presentation"
                        onClick={toggleMobileMenu(false)}
                        onKeyDown={toggleMobileMenu(false)}
                    >
                        <List>
                        <ListItem button component={Link} to="/">
                            <ListItemText primary="Our Club" />
                        </ListItem>
                        <ListItem button component={Link} to="/">
                            <ListItemText primary="Our Culture" />
                        </ListItem>
                        <ListItem button component={Link} to="/">
                            <ListItemText primary="Blog" />
                        </ListItem>
                        <ListItem button component={Link} to="/login">
                            <ListItemText primary="Login" />
                        </ListItem>
                        </List>
                    </Box>
                </Drawer>

                <Box 
                    sx={{ 
                        display: { xs: 'none', sm: 'flex' },
                        gap: 1, 
                        marginRight: 10 
                    }} 
                >  
                    {!isAuthenticated() && (
                        <>
                            <Button color="inherit" component={Link} to="/">
                                <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                    Our Culb
                                </Typography>
                            </Button>
                            <Button color="inherit" component={Link} to="/">
                                <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                    Our Culture
                                </Typography>
                            </Button>
                            <Button color="inherit" component={Link} to="/">
                                <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                    Blog
                                </Typography>
                            </Button>
                            <Button color="inherit" component={Link} to="/login">
                                <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                    Login
                                </Typography>
                            </Button>
                        </>
                    )}

                    {isAuthenticated() && role === 'Member' && (
                        <>
                        <Button color="inherit" component={Link} to="/">
                            <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                Our Culb
                            </Typography>
                        </Button>
                        <Button color="inherit" component={Link} to="/">
                            <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                Our Culture
                            </Typography>
                            <Button color="inherit" component={Link} to="/">
                                <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                    Blog
                                </Typography>
                            </Button>
                        </Button>
                        <Button color="inherit" component={Link} to="/mycourses">
                            <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                Member Dashboard
                            </Typography>
                        </Button>

                        {/* User Profile Picture */}
                        <IconButton color="inherit" onClick={handleProfileMenuOpen} sx={{ display: 'flex', alignItems: 'center', padding: '0' }}>
                            {user.profilePicture ? (
                            <Avatar src={user.profilePicture} style={{ fontSize: '0.9rem'}} />
                            ) : (
                                <AccountCircle style={{ fontSize: '0.9rem'}} />
                            )} 
                            <ArrowDropDownIcon /> 
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleProfileMenuClose}
                        >
                            <MenuItem onClick={handleViewProfile}>Profile</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                        </>
                    )}
                    {/* <Button color="inherit" component={Link} to="/members">Members</Button> */}
                    {/* <Button color="inherit" component={Link} to="/admin">Admin Dashboard</Button> */}
                    
                    {isAuthenticated() && role === 'Manager' && (
                        <>
                        {/* Members */}
                        <Button color="inherit" component={Link} to="/members">
                            <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                Members
                            </Typography>
                        </Button>

                        {/* Authors */}
                        <Button color="inherit" component={Link} to="/authors">
                            <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                Authors
                            </Typography>
                        </Button>

                        {/* Memberships */}
                        <Button color="inherit" component={Link} to="/memberships">
                            <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                Memberships
                            </Typography>
                        </Button>

                        {/* Courses */}
                        <Button color="inherit" component={Link} to="/courses">
                            <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                Courses
                            </Typography>
                        </Button>

                        {/* Lessons */}
                        <Button color="inherit" component={Link} to="/lessons">
                            <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                Lessons
                            </Typography>
                        </Button>

                        {/* SubLessons */}
                        {/* <Button color="inherit" component={Link} to="/sublessons">
                            <Typography variant="outlined" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                Sublessons
                            </Typography>
                        </Button> */}

                        {/* Notification Center */}
                        <IconButton color="inherit" onClick={handleNotificationsMenuOpen} >
                            {/* style="margin-right: 10px; fontSize: 0.8rem"> */}
                            <Badge badgeContent={1} color="error">
                            <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <Menu
                            anchorEl={notificationsAnchorEl}
                            open={Boolean(notificationsAnchorEl)}
                            onClose={handleNotificationsMenuClose}
                        >
                            <MenuItem onClick={handleNotificationsMenuClose}>No new notifications</MenuItem>
                            {/* You can loop through notifications here */}
                        </Menu>

                        {/* User Profile Picture */}
                        <IconButton color="inherit" onClick={handleProfileMenuOpen} sx={{ display: 'flex', alignItems: 'center', padding: '0' }}>
                            {user.profilePicture ? (
                            <Avatar src={user.profilePicture} style={{ fontSize: '0.9rem'}} />
                            ) : (
                                <AccountCircle style={{ fontSize: '0.9rem'}} />
                            )} 
                            <ArrowDropDownIcon /> 
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleProfileMenuClose}
                        >
                            <MenuItem onClick={handleViewProfile}>Profile</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>

                        {/* Language Selector */}
                        <IconButton color="inherit" onClick={handleLanguageMenuOpen} sx={{ display: 'flex', alignItems: 'center', padding: '0' }}>
                            <Typography variant="outlined" sx={{ fontSize: '0.9rem', fontWeight: 'bold' }}>{language}</Typography>
                            <ArrowDropDownIcon /> 
                        </IconButton>
                        <Menu
                            anchorEl={languageAnchorEl}
                            open={Boolean(languageAnchorEl)}
                            onClose={handleLanguageMenuClose}
                        >
                            <MenuItem onClick={() => changeLanguage('EN')}>EN</MenuItem>
                            <MenuItem onClick={() => changeLanguage('RO')}>RO</MenuItem>
                        </Menu>
                        
                      </>
                    )}
                    {/* <Button color="inherit" component={Link} to="/register">Register</Button> */}
               
                </Box>
            </Toolbar>
        </AppBar>
    )
};

export default Navbar;