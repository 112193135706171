export const getVideoDuration = (videoUrl) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.src = videoUrl;
  
      video.addEventListener("loadedmetadata", () => {
        resolve(video.duration); // Returns duration in seconds
      });
  
      video.addEventListener("error", () => {
        reject("Error loading video metadata");
      });
    });
  };