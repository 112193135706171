import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Typography, List, ListItem, ListItemText, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import VideoPlayer from "../../components/VideoPlayer";
import { getVideoDuration } from "../../components/VideoUtils"; // Utility for video duration

const CoursePage = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const { id } = useParams(); // Get the course ID from the URL
  const [course, setCourse] = useState({
    memberships: [],
    lessons: [],
    authors: [],
    resources: [],
  });
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [videoProgress, setVideoProgress] = useState({});
  const [completedLessons, setCompletedLessons] = useState(new Set());

  // Function to update progress when video plays
  const handleVideoProgress = (progress) => {
    if (selectedLesson) {
      setVideoProgress((prev) => ({
        ...prev,
        [selectedLesson.id]: progress.playedSeconds,
      }));
    }
  };

  // Mark lesson as completed
  const handleMarkAsComplete = () => {
    if (selectedLesson) {
      setCompletedLessons((prev) => new Set(prev.add(selectedLesson.id)));
    }
  };

  // Fetch course details
  useEffect(() => {
    const fetchCourse = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_BASE_URL}/courses/course/${id}`
        );

        // Extract lesson durations
        const lessonsWithDuration = await Promise.all(
          response.data.lessons.map(async (lesson) => {
            try {
              const duration = await getVideoDuration(lesson.videoUrl); // Extract duration
              return { ...lesson, duration: formatDuration(duration) }; // Add formatted duration
            } catch {
              return { ...lesson, duration: "Unknown" };
            }
          })
        );

        setCourse({ ...response.data, lessons: lessonsWithDuration });
      } catch (err) {
        console.error("Error fetching course:", err);
        setError("Failed to load course details. Please refresh the page.");
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [id]);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        color: "#000",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 2,
        gap: 2,
      }}
    >
      {/* Left Section: Video and Details */}
      <Box sx={{ flex: 4, paddingRight: 4 }}>
        {selectedLesson ? (
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
              padding: 2,
            }}
          >
            <Typography variant="h5" gutterBottom>
              {selectedLesson.title}
            </Typography>

            <VideoPlayer
              blobUrl={selectedLesson.videoUrl}
              onProgress={handleVideoProgress}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleMarkAsComplete}
              sx={{ marginTop: 2, color: "#fff" }}
            >
              {completedLessons.has(selectedLesson.id)
                ? "Completed"
                : "Mark as Complete"}
            </Button>
          </Box>
        ) : (
          <>
            <Typography variant="h3" fontWeight="bold" gutterBottom>
              {course.title}
            </Typography>

            <Box
              component="img"
              src={course.thumbnailUrl}
              alt={course.title}
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: 2,
              }}
            />
            {course.authors.map((author) => (
              <Typography
                variant="body1"
                mt={2}
                sx={{ color: "#189AB4" }}
                key={author.id}
              >
                {author.name}
              </Typography>
            ))}

            <Typography variant="body1" mt={2}>
              {course.description}
            </Typography>
          </>
        )}
      </Box>

      {/* Right Section: Lessons */}
      <Box
        sx={{
          flex: 2,
          backgroundColor: "#f0f0f0",
          borderRadius: 2,
          padding: 2,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Lessons
        </Typography>

        {course.lessons && course.lessons.length > 0 ? (
          <List
            sx={{
              maxHeight: "calc(100vh - 200px)",
              overflowY: "auto",
              padding: 0,
            }}
          >
            {course.lessons.map((lesson, index) => (
              <ListItem
                key={`${lesson.id}-${index}`}
                onClick={() => setSelectedLesson(lesson)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#f0f0f0",
                  borderRadius: 2,
                  padding: 1.5,
                  marginBottom: 1.5,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                <Box
                  component="img"
                  src={lesson.thumbnail || course.thumbnailUrl}
                  alt={lesson.title}
                  sx={{
                    width: 100,
                    height: 60,
                    objectFit: "cover",
                    borderRadius: 2,
                    marginRight: 2,
                  }}
                />

                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "#000",
                      }}
                    >
                      {lesson.title}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      sx={{ color: "#189AB4" }}
                    >
                      {lesson.duration || "Unknown"} minutes
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1" color="#aaa" mt={2}>
            No lessons available for this course.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CoursePage;

/**
 * Utility: Formats video duration (in seconds) to `mm:ss`
 */
const formatDuration = (durationInSeconds) => {
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = Math.floor(durationInSeconds % 60);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};
  