import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Box, Typography, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ResetPasswordPage = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); // Extract token from URL
  //const email = searchParams.get('email');

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setError('New password and confirm password do not match');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/account/reset-password`, {
        email,
        newPassword,
        confirmNewPassword,
        token,
      });
      alert('Password reset successful');
    } catch (err) {
      setError('Password reset failed: ' + err.response.data);
    }
  };

  return (
    <Container sx={{ mt: 8, maxWidth: '600px !important' }}>
        <Box 
            sx={{
            mt: 4,
            p: 6,
            boxShadow: 3,
            borderRadius: 2,
            }}
        > 
          <Typography variant="h2" align='center' gutterBottom sx={{ marginBottom: '50px', fontWeight: 'bold'}}>
            Reset Password
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: '30px' }}>
              <TextField
                  label="Email address"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  sx={{ marginBottom: '20px' }}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: '20px' }}>
              <TextField
                label="New Password"
                type={showNewPassword ? 'text' : 'password'}
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                sx={{ marginBottom: '20px' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: '20px' }}>
              <TextField
                label="Confirm New Password"
                type={showConfirmNewPassword ? 'text' : 'password'}
                fullWidth
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
                sx={{ marginBottom: '20px' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                        edge="end"
                      >
                        {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            
            {/* <TextField
              label="Reset Token"
              type="text"
              fullWidth
              value={token}
              onChange={(e) => setToken(e.target.value)}
              required
              sx={{ marginBottom: '20px' }}
            /> */}
            
            <Box sx={{ display: 'flex', gap: 2, marginBottom: '20px' }}>
              {error && <Typography color="error">{error}</Typography>}
            </Box>

            <Button variant="contained" color="secondary" type="submit" fullWidth style={{ color: 'white' }}>
              Reset Password
            </Button>
            </form>
        </Box>
    </Container>
  );
};

export default ResetPasswordPage;
