import React, {useState} from "react";
import { Box, Typography, Link, Stack, Modal, Button } from "@mui/material";
import logo from '../assets/images/logos/Logo-01.png';
import CopyrightIcon from '@mui/icons-material/Copyright';
import TermsAndConditionsPage from '../pages/members/TermsAndConditionsPage';

const Footer = () => {
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  return (
    <Box
      sx={{
        py: 4,
        px: 4,
        borderTop: "1px solid #E0E0E0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* Top Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "80%",
          mb: 2,
          flexWrap: "wrap",
        }}
      >
        {/* Logo */}
        <Box 
          component="img" 
          src={logo} 
          alt="Logo" 
          sx={{ 
            height: { xs: 25, sm: 45 }, 
            margin: 0 
          }} 
        />

        {/* Navigation Links */}
        <Stack
          direction="row"
          spacing={4}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link href="#" underline="none" sx={{ color: "#000000", fontSize: "14px" }}>
            Our Club
          </Link>
          <Link href="#" underline="none" sx={{ color: "#000000", fontSize: "14px" }}>
            Our Culture
          </Link>
          <Link href="#" underline="none" sx={{ color: "#000000", fontSize: "14px" }}>
            Blog
          </Link>
          <Link href="tel:+40749345433" underline="none" sx={{ color: "#000000", fontSize: "14px" }}>
            Call Us
          </Link>
        </Stack>
      </Box>

      {/* Bottom Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "80%",
          flexWrap: "wrap",
        }}
      >
        {/* Copyright */}
        <Typography
          variant="body2"
          sx={{
            color: "#A0A0A0",
            fontSize: "12px",
            mb: { xs: 2, sm: 0 },
          }}
        >
          <CopyrightIcon /> Copyright Academillion 2024. All Rights Reserved.
        </Typography>

        {/* Policy Links */}
        <Stack direction="row" spacing={2} sx={{ textAlign: "center" }}>
          <Link
            href="/"
            underline="none"
            sx={{ color: "#189AB4", fontSize: "12px" }}
            onClick={(e) => {
              e.preventDefault(); // Prevent default navigation behavior
              setTermsModalOpen(true); // Open the modal
            }}
          >
            Terms & Conditions
          </Link>
          <Link
            href="#"
            underline="none"
            sx={{ color: "#189AB4", fontSize: "12px" }}
          >
            Cookie Policy
          </Link>
        </Stack>
      </Box>
    
      {/* Modal for Terms & Conditions */}
      <Modal
        open={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
        aria-labelledby="terms-modal-title"
        aria-describedby="terms-modal-description"
      >
        <Box
          sx={{
            width: "80%",
            bgcolor: "background.paper",
            margin: "auto",
            mt: "5%",
            p: 2,
            borderRadius: 2,
            boxShadow: 24,
            maxHeight: "100vh",
            overflowY: "auto",
          }}
        >
          {/* Embed TermsAndConditionsPage with Scroll Event */}
          <Box
            id="terms-modal-content"
            sx={{ maxHeight: "70vh", overflowY: "scroll", pr: 1 }}
          >
            <TermsAndConditionsPage />
          </Box>

          {/* Close Modal Button */}
          <Button
            onClick={() => setTermsModalOpen(false)}
            variant="contained"
            color="primary"
            sx={{ mt: 2, color: '#fff' }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Footer;