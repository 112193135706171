import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import {
  Box,
  Slider,
  Button,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Forward10Icon from "@mui/icons-material/Forward10";
import Replay10Icon from "@mui/icons-material/Replay10";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

const VideoPlayer = ({ blobUrl }) => {
  const playerRef = useRef(null);
  const containerRef = useRef(null); // For fullscreen
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0); // Progress in seconds
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0.8);
  const [playbackRate, setPlaybackRate] = useState(1); // Default playback rate is 1x
  const [rateAnchor, setRateAnchor] = useState(null); // For playback rate menu

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleSeek = (event, newValue) => {
    playerRef.current.seekTo(newValue, "seconds");
    setProgress(newValue);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
  };

  const handleRateChange = (rate) => {
    setPlaybackRate(rate);
    setRateAnchor(null); // Close the playback rate menu
  };

  const handleProgressUpdate = (state) => {
    setProgress(state.playedSeconds);
  };

  const handleFullscreen = () => {
    if (containerRef.current.requestFullscreen) {
      containerRef.current.requestFullscreen();
    } else if (containerRef.current.webkitRequestFullscreen) {
      containerRef.current.webkitRequestFullscreen();
    } else if (containerRef.current.mozRequestFullScreen) {
      containerRef.current.mozRequestFullScreen();
    } else if (containerRef.current.msRequestFullscreen) {
      containerRef.current.msRequestFullscreen();
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <Box
      ref={containerRef} // Reference for fullscreen
      sx={{
        width: "50%",
        backgroundColor: "#f0f0f0",
        borderRadius: 2,
        overflow: "hidden",
      }}
    >
      {/* React Player */}
      <ReactPlayer
        ref={playerRef}
        url={blobUrl}
        playing={playing}
        controls={false} // Hide default controls
        width="100%"
        height="100%"
        volume={volume}
        playbackRate={playbackRate} // Add playback rate
        onProgress={handleProgressUpdate}
        onDuration={(dur) => setDuration(dur)}
      />

      {/* Custom Controls */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f0f0f0",
          padding: 2,
        }}
      >
        {/* Time Slider */}
        <Slider
          value={progress}
          max={duration}
          onChange={handleSeek}
          sx={{
            color: "#189AB4",
            borderColor: "#189AB4",
            height: 8,
            "& .MuiSlider-thumb": { height: 16, width: 16 },
          }}
        />

        {/* Controls Row */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // Separate left and right groups
            alignItems: "center",
            marginTop: 1,
          }}
        >
          {/* Left Group */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {/* Play/Pause Button */}
            <IconButton onClick={handlePlayPause} sx={{ color: "#189AB4" }}>
              {playing ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>

            {/* Replay and Forward Buttons */}
            <IconButton
              onClick={() => playerRef.current.seekTo(Math.max(0, progress - 10), "seconds")}
              sx={{ color: "#189AB4" }}
            >
              <Replay10Icon />
            </IconButton>
            <IconButton
              onClick={() => playerRef.current.seekTo(Math.min(duration, progress + 10), "seconds")}
              sx={{ color: "#189AB4" }}
            >
              <Forward10Icon />
            </IconButton>

            {/* Time Display */}
            <Typography sx={{ color: "#189AB4" }}>
              {formatTime(progress)} / {formatTime(duration)}
            </Typography>
          </Box>

          {/* Right Group */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {/* Volume Control */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <VolumeUpIcon sx={{ color: "#189AB4" }} />
              <Slider
                value={volume}
                onChange={handleVolumeChange}
                max={1}
                step={0.1}
                //sx={{ width: 100, color: "#189AB4"}}
                sx={{
                    width: 100,
                    color: "#189AB4",
                    "& .MuiSlider-thumb": {
                      height: 16,
                      width: 16,
                      backgroundColor: "#189AB4",
                      "&:hover, &.Mui-focusVisible, &.Mui-active": {
                        boxShadow: "0px 0px 0px 8px rgba(24, 154, 180, 0.16)",
                      },
                    },
                    "& .MuiSlider-track": {
                      border: "none",
                    },
                    "& .MuiSlider-rail": {
                      backgroundColor: "#b3e5ec",
                    },
                  }}
              />
            </Box>

            {/* Playback Rate Menu */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Button
                onClick={(event) => setRateAnchor(event.currentTarget)}
                sx={{ color: "#189AB4", textTransform: "none" }}
              >
                {playbackRate}x
              </Button>
              <Menu
                anchorEl={rateAnchor}
                open={Boolean(rateAnchor)}
                onClose={() => setRateAnchor(null)}
              >
                {[0.5, 1, 1.5, 2].map((rate) => (
                  <MenuItem key={rate} onClick={() => handleRateChange(rate)}>
                    {rate}x
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* Fullscreen Button */}
            <IconButton onClick={handleFullscreen} sx={{ color: "#189AB4" }}>
              <FullscreenIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VideoPlayer;
