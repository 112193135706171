import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { Container, Typography, Box, Button, Grid, Card, CardMedia, CardContent, Stack, TextField, Checkbox, FormControl
  , FormGroup, FormControlLabel, TextareaAutosize, Divider, Accordion, AccordionSummary, AccordionDetails, Avatar
 } from '@mui/material';
 import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";

import Footer from "../components/Footer";


const VisitorsPage = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  const [isPlaying, setIsPlaying] = useState(false);
  const [authors, setAuthors] = useState([]);

  // Custom Left Arrow Component
const CustomLeftArrow = ({ onClick }) => (
  <Box
    onClick={onClick}
    sx={{
      position: "absolute",
      top: "50%",
      left: "-50px",
      transform: "translateY(-50%)",
      zIndex: 1,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Typography
      sx={{
        fontSize: "80px",
        color: "#003366",
      }}
    >
      &#8249;
    </Typography>
  </Box>
);

// Custom Right Arrow Component
const CustomRightArrow = ({ onClick }) => (
  <Box
    onClick={onClick}
    sx={{
      position: "absolute",
      top: "50%",
      right: "-50px",
      transform: "translateY(-50%)",
      zIndex: 1,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
    }}
  >
    <Typography
      sx={{
        fontSize: "80px",
        color: "#003366",
      }}
    >
      &#8250;
    </Typography>
  </Box>
);

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    arrows: true,
    prevArrow: <CustomLeftArrow />, // Custom Left Arrow
    nextArrow: <CustomRightArrow />, // Custom Right Arrow
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 1 } },
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
    ],
  };

  const authorsAM = [
    { name: "Mihai Petian", pictureUrl: "/mihai-bg-grey.png", expertise: "Co-fondator & Business Creator" },
    { name: "Denisa Stănilă", pictureUrl: "/deni-bg-grey.png", expertise: "Co-fondator & Business Arhitect" },
    { name: "Lavinia Motoc", pictureUrl: "/lavi-bg-grey.png", expertise: "Co-fondator & Money Maker" },
    { name: "Sabina Bodea", pictureUrl: "/sabi-bg-gri.png", expertise: "Co-fondator & Experience Alchemist" },
  ];

  // Images and titles
  const items = [
    { title: "REAL ESTATE", image: "/oc1.png" },
    { title: "CRYPTO", image: "/oc3.png" },
    { title: "STOCK", image: "/oc5.png" },
    { title: "ART", image: "/oc4.png" },
    { title: "JOINT VENTURES", image: "/oc2.png" },
  ];

  const activities = [
    {
      title: "Business Holidays",
      description:
        "Exclusive trips where members combine learning, networking, and relaxation, making progress while enjoying scenic destinations.",
      image: "/a1.png", // Replace with the actual image URL
    },
    {
      title: "Community events",
      description:
        "Regular gatherings like networking galas, industry talks, and local meetups to facilitate meaningful connections and shared learning.",
      image: "/a2.png", // Replace with the actual image URL
    },
    {
      title: "Investment hackathons",
      description:
        "Weekend-long hackathons where members tackle investment challenges, brainstorm ventures, and pitch ideas in a fun, dynamic setting.",
      image: "/a4.png", // Replace with the actual image URL
    },
    {
      title: "Wellness & mindset escapes",
      description:
        "Retreats focused on personal growth, mindfulness, yoga, and meditation alongside networking opportunities, blending inner development with outward success.",
      image: "/a3.png", // Replace with the actual image URL
    },
  ];

  const stats = [
    {
      number: "20",
      description: "Comprehensive online courses to learn at your pace and build real skills",
    },
    {
      number: "15+",
      description: "Top-tier trainers and experts guiding your financial journey",
    },
    {
      number: "822.000 €",
      description: "Collectively invested by our community in under 3 months",
    },
    {
      number: "100+",
      description: "Members creating a network of shared opportunities and growth",
    },
  ];
  
  const testimonials = [
    {
      video: "/testimonial-alexandru-tarcea.mp4",
      alt: "Testimonial Alexandru Tarcea",
    },
    {
      video: "/testimonial-andreea-birsan.mp4",
      alt: "Testimonial Andreea Birsan",
    },
    {
      video: "/testimonial-iulian-motoc.mov", 
      alt: "Testimonial Iulian Motoc",
    },
    {
      video: "/testimonial-michaela-petian.mov",
      alt: "Testimonial Michaela Petian",
    },
    {
      video: "/testimonial-oana-manase.mp4", 
      alt: "Testimonial Oana Manase",
    },
    {
      video: "/testimonial-unknown.mp4",
      alt: "Testimonial"
    },
  ];

  const textTestimonials = [
    {
      name: "Andrei Iliuț, Entrepreneur & Senior software engineer",
      text: "I joined this club to network and learn more about the real estate field. Here, I found an amazing community of wonderful people and inspiring entrepreneurs with whom you can share valuable ideas and experiences. Moreover, I’ve managed to collaborate with some of the club members and develop exciting projects. It’s a fantastic environment for both personal and professional growth!",
      avatar: "/testimonial-avatar-andrei.jpeg",
    },
    {
      name: "Cristina Irimie, Entrepreneur & Investor",
      text: "In AM community, you find the courage to learn and grow, knowing you're supported in reaching your fullest potential. With Academillion, we evolve beautifully, together.",
      avatar: "/testimonial-avatar-cristina.jpeg",
    },
    {
      name: "Elena Deacu, Therapist&Investor",
      text: "I love new projects initiated by passionate people, full of positive energy and laughter! In the Academillion community, I’ve found people like me, simple, cheerful, and ready to get involved and support others. The people at Academillion bring a wealth of expertise and openness, always willing to help, contribute, and join my mission of creating a generation for whom abundance is the norm. Being a member of Academillion means education, professionally assessed investment opportunities, balance, and relaxation.",
      avatar: "/testimonial-avatar-elena.jpeg",
    },
    {
      name: "Mihai Udrescu, Entreprenor",
      text: "Academillion provides great investment guidance through its talented team, offering strategies and a supportive community. It’s inspiring to see their dedication to supporting the expat community as well.",
      avatar: "/testimonial-avatar-mihai.jpeg",
    },
  ];
  

  const memberships = [
    {
      title: "TITANIUM",
      features: [
        "Tailored Financial Plan to financial freedom.",
        "5 One-on-One Financial Coaching sessions.",
        "Acces to a Finance and Investments platform.",
        "24 Advanced Workshops with Club Experts.",
        "24 Community Mastermind sessions.",
        "Networking, events, and fun experiences.",
      ],
      color: "#5FD6DA",
      backgroundColor: "#5FD6DA",
    },
    {
      title: "GOLD",
      features: [
        "Tailored Financial Plan to financial freedom.",
        "10 One-on-One Financial Coaching sessions.",
        "Acces to a Finance and Investments platform.",
        "24 Advanced Workshops with Club Experts.",
        "24 Community Mastermind sessions.",
        "Networking, events, and fun experiences.",
        "Access to high-return Investments.",
        "Accountability partner to track your goals.",
      ],
      color: "#189AB4",
      backgroundColor: "#189AB4",
      recommended: true,
    },
    {
      title: "STARLIGHT",
      features: [
        "Tailored Financial Plan to financial freedom.",
        "12 One-on-One Financial Coaching sessions.",
        "Acces to a Finance and Investments platform.",
        "24 Advanced Workshops with Club Experts.",
        "24 Community Mastermind sessions.",
        "Networking, events, and fun experiences.",
        "Priority to high-return Investments.",
        "Role as a Business Angel in startups.",
        "Accountability partner to track your goals.",
        "Acces to Financial Freedom Summit Live Event.",
        "Exclusive meetings and Legendary experiences.",
      ],
      color: "#0A435E",
      backgroundColor: "#0A435E",
    },
  ];

  const promises = [
    {
      icon: "/no-hidden-fees.png", 
      title: "No Hidden Fees",
    },
    {
      icon: "/money-back.png",
      title: "15 Days Money Back",
    },
    {
      icon: "/tailored-experience.png", 
      title: "Tailored Experience",
    },
    {
      icon: "/events-priority.png",
      title: "Events Priority Access",
    },
    {
      icon: "/exclusive-content.png",
      title: "Exclusive Content",
    },
    {
      icon: "/community-ambassadors.png",
      title: "Regular Community Ambassadors 1:1",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
      question: "What is Academillion?",
      answer: "Academillion is a transformative platform that blends education, investment opportunities, and a thriving community to help you grow smarter, connect deeper, and achieve more.",
    },
    {
      question: "Which package is right for me?",
      answer: `Choose the package that fits your goals:
      • TITANIUM: Designed for those who want clarity, control, and support in managing their finances. This membership provides access to expert financial coaching and structured learning to help you create a solid foundation for your financial journey.
      • GOLD: Perfect for investors looking to unlock exclusive opportunities in crypto, real estate, joint ventures, and more. This package is tailored to those ready to grow their wealth strategically.
      • STARLIGHT: Crafted for those who value lifestyle as much as financial success. This package blends premium investment opportunities with exclusive experiences, retreats, and a community focused on living life to the fullest
      `,
    },
    {
      question: "Who creates the Academillion courses?",
      answer: "Academillion courses are crafted by industry experts and seasoned professionals who share practical knowledge, insights, and strategies from their real-world experience.",
    },
    {
      question: "How does the guarantee work?",
      answer: "You have 15 days to test the platform risk-free. If it doesn’t meet your expectations, simply contact support office@academillion.com for a full refund.",
    },
    {
      question: "Can I pay via bank transfer, cash, or other methods?",
      answer: "Payments are accepted exclusively via bank transfer.",
    },
    {
      question: "Can I pay on behalf of a business?",
      answer: "We issue tax invoices for every member. You can use your company’s card to pay for membership and receive a tax invoice deductible for your business. For a complete invoice, please ensure your company’s billing details are provided when issuing the invoice.",
    },
  ];

  const handleAccordionChange = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const blogPosts = [
    {
      id: 1,
      title: "Design That Pays: Adding Value Through Innovation",
      date: "2 NOV 2024",
      author: "Andreea Birsan",
      excerpt:
        "Small design changes can make a big impact on your property’s value. This blog explores the easiest and most effective updates to create a space that looks amazing and sells even better.",
      image: "/blog1.png", 
    },
    {
      id: 2,
      title: "2025, the Year of Financial Mastery",
      date: "30 OCT 2024",
      author: "Marian Papară",
      excerpt:
        "Step into the future with confidence. Discover key strategies to master your finances, achieve your goals, and turn 2025 into your most successful year yet.",
      image: "/blog2.png", 
    },
    {
      id: 3,
      title: "Boosting Financial Growth with Tech and Tokens",
      date: "22 OCT 2024",
      author: "Diana Chirtop",
      excerpt:
        "In today's fast-paced digital world, leveraging innovative technology can be key to achieving financial growth. Portable SSDs, like the one pictured above, symbolize the efficiency and security needed to store digital assets safely.",
      image: "/blog3.png", 
    },
    {
      id: 4,
      title: "Adventures with a Purpose: Driving Towards Wealth",
      date: "16 OCT 2024",
      author: "Sabina Bodea",
      excerpt:
        "Embark on a journey where financial freedom meets the thrill of exploration. Learn how to align your financial goals with your passions, creating a roadmap for wealth and adventure that fuels both your wallet and your soul.",
      image: "/blog4.png", 
    },
  ];

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        // Replace with the actual API URL to fetch authors
        const response = await axios.get('https://localhost:7095/api/authors');
        setAuthors(response.data);
      } catch (error) {
        console.error('Error fetching authors:', error);
      }
    };

    const loadTypeformScript = () => {
      const script = document.createElement('script');
      script.src = "//embed.typeform.com/next/embed.js";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        // Cleanup script on unmount
        document.body.removeChild(script);
      };
    };

    fetchAuthors();
    const cleanupTypeformScript = loadTypeformScript();

    // Return cleanup function for both functionalities
    return () => {
      cleanupTypeformScript();
    };
  }, []);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <>
    <Box
      sx={{
        background: 'linear-gradient(135deg, #1a2b4c, #2b67ce)',
        color: 'white',
        height: '700px',
        textAlign: 'center',
        py: 6,
        position: 'relative',
      }}
    >
      {/* Background Arrows */}
      <Box
        sx={{
          position: 'absolute',
          height: '700px',
          inset: 0,
          background: 'url("/rectangle.png") no-repeat center/cover',
          //opacity: 0.2,
          zIndex: 0,
        }}
      />

      <Container sx={{ zIndex: 1, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* Heading */}
        <Typography
          variant="h2"
          sx={{
            //mb: 2,
            fontFamily: 'Instrument Sans, sans-serif',
            fontWeight: 'bold',
            fontSize: '48px',
            lineHeight: 1.2,
            color: '#fff',
          }}
        >
          From learning to earning
        </Typography>

        {/* Subheading */}
        <Typography
          variant="body1"
          sx={{
            mt: 2,
            color: '#fff',
            fontSize: '23px',
            lineHeight: 1.6,
          }}
        >
          Your gateway to smarter investments, stronger connections, 
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mb: 2,
            color: '#fff',
            fontSize: '23px',
            lineHeight: 1.6,
          }}
        >
          and a brighter future
        </Typography>

        {/* Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mb: 4, mt: 2 }}>
          <Button
            href="tel:+40749345433"
            variant="contained"
            sx={{
              //width: '245px',
              width: { xs: "80%", sm: "50%", md: "245px" },
              fontSize: '18px',
              fontWeight: 'bold',
              backgroundColor: '#fff',
              borderRadius: '16px',
              color: '#1a2b4c',
              '&:hover': { backgroundColor: '#f0f0f0' },
            }}
          >
            Let’s Talk Growth
          </Button>
          <Button
            href="tel:+40749345433"
            variant="outlined"
            sx={{
              //width: '245px',
              width: { xs: "80%", sm: "50%", md: "245px" },
              fontSize: '18px',
              fontWeight: 'bold',
              borderColor: '#fff',
              borderRadius: '16px',
              color: '#fff',
              '&:hover': { borderColor: '#fff', color: '#f0f0f0' },
            }}
          >
            Find out more
          </Button>
        </Box>

        {/* Video or Thumbnail */}
        <Box
          sx={{
            mb: 4,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center', // Ensures center alignment
            width: '100%',
            maxWidth: 720,
            aspectRatio: '16 / 9',
            borderRadius: 2,
            overflow: 'hidden',
            transform: 'translateY(20px)', // Overlapping effect
          }}
        >
          {!isPlaying ? (
            // Thumbnail with Play Button
            <Box
              onClick={handlePlay}
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                background: 'url("/thumbnail.png") no-repeat center/cover',
              }}
            >
              {/* Play Button */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '124px', // Adjust to fit your play button PNG size
                  height: '124px',
                }}
              >
                <img
                  src="/play-button.png"
                  alt="Play Button"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Box>
            </Box>
          ) : (
            // Video Player
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
            >
              <source src="/intro.mp4" type="video/mp4" />
            </video>
          )}
        </Box>


        <Box sx={{ backgroundColor: '#f8f8f8', py: 8, marginTop: '30px' }}>
          <Typography
            variant="h3"
            sx={{
              //mb: 2,
              fontFamily: 'Instrument Sans, sans-serif',
              fontWeight: 'bold',
              lineHeight: 1.2,
              color: '#189AB4',
            }}
          >
            Meet the Academillionaires: your guides to growth
          </Typography>
          <Container maxWidth="md">
            {/* Author Cards Section */}
            <Box 
              sx={{
                display: 'flex', 
                flexWrap: 'wrap', 
                justifyContent: 'space-between',
                gap: 2,
                mb: 6
              }}
            >
              {authorsAM.map((author) => (
                <Box
                  key={author.id}
                  sx={{
                    flex: '1 1 calc(20% - 16px)', // Makes cards responsive and allows wrapping
                    minWidth: 160, // Ensures the card has a minimum width
                    maxWidth: 250, // Limit max width for the card
                    //backgroundColor: '#fff',
                    borderRadius: 2,
                    //boxShadow: 2,
                    textAlign: 'center',
                    p: 2,
                    position: 'relative'
                  }}
                >
                  <Box
                    component="img"
                    src={author.pictureUrl}
                    alt={author.name}
                    sx={{
                      width: '100%',
                      height: {xs: 250, md: 350},
                      objectFit: 'cover',
                      borderRadius: 5
                    }}
                  />
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                    {author.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#007C83', fontWeight: 'bold' }}>
                    {author.expertise}
                  </Typography>
                </Box>
              ))}
            </Box>

            
            <Box
              sx={{
                py: 6,
                px: 3,
                display: "flex",
                flexDirection: { xs: "column", md: "row" }, // Stack on small screens, side by side on larger screens
                alignItems: "center",
                gap: 4,
              }}
            >
              {/* Text Section on the Left */}
              <Box sx={{ flex: 1, textAlign: { xs: "center", md: "left" } }}>
                <Typography
                  variant="h3"
                  sx={{ fontWeight: "bold", mb: 2, color: "#189AB4" }}
                >
                  Why you’ll love being part of our community
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mb: 4, color: "#5f6368", maxWidth: "600px" }}
                >
                  Step into a world of ambition, growth, and meaningful connections designed
                  for people like you who aspire to live, learn, and invest smarter.
                </Typography>
              </Box>

              {/* Icons Section on the Right */}
              <Box
                sx={{
                  flex: 1,
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)", // Two icons per row
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#F6F6F6",
                      padding: 2,
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/industry-leaders.png"
                      alt="Learn"
                      style={{ width: 48, height: 48 }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", mt: 2, color: "#333" }}
                  >
                    Learn from Industry Leaders
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#F6F6F6",
                      padding: 2,
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/exclusive-opportunities.png"
                      alt="Opportunities"
                      style={{ width: 48, height: 48 }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", mt: 2, color: "#333" }}
                  >
                    Access Exclusive Opportunities
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#F6F6F6",
                      padding: 2,
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/community.png"
                      alt="Grow"
                      style={{ width: 48, height: 48 }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", mt: 2, color: "#333" }}
                  >
                    Grow with a Like-Minded Community
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#F6F6F6",
                      padding: 2,
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/financial-freedom.png"
                      alt="Freedom"
                      style={{ width: 48, height: 48 }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", mt: 2, color: "#333" }}
                  >
                    Build Your Financial Freedom
                  </Typography>
                </Box>
              </Box>
            </Box>

          </Container>
        </Box>
      </Container>

      {/* Oportunities Slider */}
      <Box
        sx={{
          position: "relative",
          height: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        {/* Background Image */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: "url('Oportunities-carousel.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 1,
            //filter: "brightness(0.7)", // Dim the background slightly for better text contrast
          }}
        />
        {/* Content Overlay */}
        <Box sx={{ position: "relative", zIndex: 2, width: "100%" }}>
          {/* Title */}
          <Typography
            variant="h3"
            textAlign="center"
            fontWeight="bold"
            sx={{ mb: 4, color: "#0A435E", marginBottom: "45px" }}
          >
            Vehicles for building wealth
          </Typography>

          {/* Slider */}
          <Box sx={{ maxWidth: "1000px", mx: "auto" }}>
          <Slider {...settings}>
            {items.map((item, index) => (
              <Box key={index} sx={{ px: 2 }}>
                <Box
                  sx={{
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: "16px",
                    textAlign: "center",
                    //boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                    //backgroundColor: "#f5f5f5",
                  }}
                >
                  {/* Image */}
                  <Box
                    sx={{
                      position: "relative",
                      height: "300px",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    {/* Text Overlay */}
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        //background: "rgba(0, 0, 0, 0.4)", // Semi-transparent background
                        color: "#ffffff",
                        padding: "10px 0",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{ fontWeight: "bold", textTransform: "uppercase", color: "#fff" }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Slider>
          </Box>
        </Box>
      </Box>

      {/* ACTIVITIES */}
      <Box sx={{ p: 4 }}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mt: 4, mb: 4, color: '#189AB4' }}>
          More than an academy... it's a way of life!
        </Typography>
        <Stack spacing={4}>
          {activities.reduce((rows, activity, index) => {
            if (index % 2 === 0) rows.push([]);
            rows[rows.length - 1].push(activity);
            return rows;
          }, []).map((row, rowIndex) => (
            <Box
              key={rowIndex}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 4,
                justifyContent: "center",
              }}
            >
              {row.map((activity, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    gap: 2,
                    p: 2,
                    width: { xs: "100%", md: "35%" },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={activity.image}
                    alt={activity.title}
                    sx={{
                      borderRadius: 2,
                      objectFit: "cover",
                    }}
                  />
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#189AB4",
                        fontWeight: "bold",
                        mb: 1,
                      }}
                    >
                      {activity.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {activity.description}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </Stack>
      </Box>

      <div
          data-tf-live="01JE6Z43SEDZ7SHE5CSQF9HDG9"
          style={{ width: '100%', height: '500px', marginBottom: '80px' }}
        ></div>


      {/* Statistics */}
      <Box
        sx={{
          display: "flex", // Flexbox for centering
          justifyContent: "center", // Horizontally center the content
          alignItems: "center", // Vertically center the content
          backgroundColor: "#ffffff",
          py: 6,
          px: 2, // Reduce padding for mobile
          textAlign: "center",
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4} // Adjust spacing between items
          sx={{
            width: "80%", // Full width for mobile
            flexWrap: "wrap", // Ensure items wrap on small screens
          }}
        >
          {stats.map((stat, index) => (
            <Box
              key={index}
              sx={{
                textAlign: "center",
                flex: "1 1 100%", // Default to full width for mobile
                maxWidth: {xs: "45%", md: "20%"},
                //maxWidth: { xs: "100%", sm: "45%", md: "20%" }, // Adapt width for larger screens
                mb: 3, // Consistent spacing below each box
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bold",
                  color: "#189AB4",
                  mb: 1,
                  fontSize: { xs: "1.75rem", sm: "2rem", md: "2.5rem" }, // Responsive font sizes
                }}
              >
                {stat.number}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#5A5A5A",
                  fontSize: { xs: "0.9rem", sm: "1rem" }, // Adjust font size for readability
                }}
              >
                {stat.description}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Box>

      


      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/divider.png" // Replace with the actual path to your divider image
          alt="Custom Divider"
          style={{
            width: "100%", // Adjust the width as needed
            maxHeight: "2px", // Optional: Adjust height if needed
            objectFit: "cover", // Ensures the image scales properly
          }}
        />
      </Box>

      <Box sx={{ py: 6, textAlign: "center", backgroundColor: "#fff" }}>
        {/* Section Title */}
        <Typography
          variant="h3"
          sx={{ fontWeight: "bold", color: "#189AB4", mb: 4 }}
        >
          Hear from our members
        </Typography>

        {/* Testimonial Videos */}
        <Stack
          direction="row"
          spacing={4}
          justifyContent="center"
          sx={{ flexWrap: "wrap" }}
        >
          {testimonials.map((testimonial, index) => (
            <Card
              key={index}
              sx={{
                width: "150px",
                borderRadius: "16px",
                overflow: "hidden",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  transform: "scale(1.05)",
                  transition: "transform 0.3s ease-in-out",
                },
              }}
            >
              <Box
                component="video"
                src={testimonial.video}
                controls
                alt={testimonial.alt}
                sx={{
                  width: "100%",
                  height: "300px",
                  objectFit: "cover",
                  borderRadius: "16px",
                }}
              />
            </Card>
          ))}
        </Stack>

        <Box
          sx={{
            position: "relative",
            overflow: "hidden", 
            width: "100%", 
          }}
        >
          {/* Swiper Carousel */}
  <Swiper
    navigation={true}
    modules={[Navigation]}
    slidesPerView={3}
    spaceBetween={30}
    breakpoints={{
      0: { 
        slidesPerView: 1, 
        spaceBetween: 10, 
        style: { padding: "20px 10px" }, // Adjust padding for mobile
      },
      768: { slidesPerView: 2, spaceBetween: 20 },
      1024: { slidesPerView: 3, spaceBetween: 20 },
    }}
    style={{ padding: "40px 20px", width: "100%" }} // Ensure it adapts to all screen sizes
  >
    {textTestimonials.map((testimonial, index) => (
      <SwiperSlide key={index}>
        <Card
          sx={{
            p: { xs: 2, md: 3 }, // Smaller padding for mobile
            borderRadius: "16px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            width: "100%", // Ensure cards don't overflow
            maxWidth: { xs: "100%", md: "75%" }, // Adjust card size for mobile
            margin: "0 auto", // Center cards on smaller screens
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#666",
              fontSize: { xs: "12px", md: "14px" }, // Smaller font size on mobile
              lineHeight: "1.5",
            }}
          >
            {testimonial.text}
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }} // Stack vertically on mobile
            spacing={2}
            alignItems="center"
            mt={2}
          >
            <Avatar
              src={testimonial.avatar}
              alt={testimonial.name}
              sx={{
                width: { xs: 48, md: 56 }, // Smaller avatar on mobile
                height: { xs: 48, md: 56 },
                border: "2px solid #189AB4",
              }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#189AB4",
                fontSize: { xs: "14px", md: "16px" }, // Adjust font size for mobile
              }}
            >
              {testimonial.name}
            </Typography>
          </Stack>
        </Card>
      </SwiperSlide>
    ))}
  </Swiper>
        </Box>
      </Box>



    <Box sx={{ py: 6, backgroundColor: "#F8FAFC", textAlign: "center" }}>
      {/* Section Title */}
      <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2, color: "#189AB4" }}>
        Choose your growth plan
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, color: "#5A5A5A"}}>
      With each package, gain access to curated opportunities for personal development, financial growth, and lasting connections in a vibrant community.
      </Typography>

      {/* Membership Plans */}
      <Box sx={{ py: 2, backgroundColor: "#F8FAFC", textAlign: "center" }}>
      {/* Membership Plans */}
      <Stack
        direction="row"
        justifyContent="center"
        spacing={3}
        sx={{ flexWrap: "wrap" }}
      >
        {memberships.map((membership, index) => (
          <Card
            key={index}
            sx={{
              width: "380px",
              borderRadius: "16px",
              borderWidth: membership.recommended ? 3 : 1,
              borderColor: membership.color,
              backgroundColor: "#FFFFFF",
              //position: "relative",
              display: "flex",
              flexDirection: "column", // Stack elements naturally
              position: "relative",
              overflow: "hidden",
            }}
          >
            {/* Title Section with Background Color */}
            <Box
              sx={{
                backgroundColor: membership.backgroundColor,
                color: membership.color,
                padding: "30px",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", textTransform: "uppercase", color: "#fff", fontSize: "28px" }}
              >
                {membership.title}
              </Typography>
            </Box>

            {/* Recommended Badge */}
            {membership.recommended && (
              <Box
                sx={{
                  position: "absolute",
                  top: "20px",
                  right: "-50px",
                  backgroundColor: "#0078D7",
                  color: "#ffffff",
                  paddingTop: 1,
                  paddingBottom: 1,
                  paddingLeft: 6,
                  paddingRight: 6,
                  transform: "rotate(45deg)",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                RECOMMENDED
              </Box>
            )}

            {/* Card Content */}
            <CardContent>
              <Stack spacing={2} sx={{ mt: 2 }}>
                {membership.features.map((feature, idx) => (
                  <React.Fragment key={idx}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <CheckCircleOutlineIcon
                        sx={{ color: membership.backgroundColor, fontSize: "18px" }}
                      />
                      <Typography
                        variant="body2"
                        sx={{ color: "#5A5A5A", textAlign: "left" }}
                      >
                        {feature}
                      </Typography>
                    </Box>
                    {idx < membership.features.length - 1 && (
                      <Divider sx={{ my: 1, borderColor: "#E0E0E0" }} />
                    )}
                  </React.Fragment>
                ))}
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </Box>

      {/* Call-to-Action Button */}
      <Button
        variant="contained"
        fullWidth={false} // Adjusted for responsiveness
        href="tel:+40749345433"
        sx={{
          mt: 4,
          px: { xs: 2, sm: 4 }, // Smaller padding for mobile
          py: { xs: 1.5, sm: 2 }, // Adjust vertical padding
          width: { xs: "80%", sm: "50%", md: "15%" }, // Responsive width
          backgroundColor: "#0A435E",
          color: "#74E6DC",
          borderRadius: "24px",
          textTransform: "none",
          fontSize: { xs: "16px", sm: "18px", md: "20px" }, // Adjust font size
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#189AB4",
            color: "#fff",
          },
        }}
      >
        Get in Touch
      </Button>
    </Box>



    <Box sx={{ py: 6, backgroundColor: "#F8FAFC", textAlign: "center" }}>
      {/* Section Title */}
      <Typography variant="h3" sx={{ fontWeight: "bold", mb: 3, color: "#189AB4" }}>
        Our Promise to You
      </Typography>

      {/* Promise Items */}
      <Stack
        direction="row"
        justifyContent="center"
        flexWrap="wrap"
        gap={4}
        sx={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        {promises.map((promise, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              textAlign: "left",
              gap: 2,
              width: {
                xs: "100%", // Full width on small screens
                sm: "45%", // Two items per row on small/medium screens
                md: "30%", // Three items per row on larger screens
              },
              p: 2,
              borderRadius: "12px",
            }}
          >
            {/* Icon */}
            <Box
              sx={{
                width: "60px",
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                borderRadius: "12px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <img
                src={promise.icon}
                alt={promise.title}
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "contain",
                }}
              />
            </Box>

            {/* Text */}
            <Typography
              variant="body1"
              sx={{ color: "#5A5A5A", fontWeight: "500" }}
            >
              {promise.title}
            </Typography>
          </Box>
        ))}
      </Stack>

      {/* Call-to-Action Button */}
      <Button
        variant="contained"
        fullWidth={false} // Adjusted for responsiveness
        href="tel:+40749345433"
        sx={{
          mt: 4,
          px: { xs: 2, sm: 4 }, // Smaller padding for mobile
          py: { xs: 1.5, sm: 2 }, // Adjust vertical padding
          width: { xs: "80%", sm: "50%", md: "15%" }, // Responsive width
          backgroundColor: "#0A435E",
          color: "#74E6DC",
          borderRadius: "24px",
          textTransform: "none",
          fontSize: { xs: "16px", sm: "18px", md: "20px" }, // Adjust font size
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#189AB4",
            color: "#fff",
          },
        }}
      >
        Take the next step
      </Button>
    </Box>

    <Box sx={{ py: 6, px: 4, backgroundColor: "#F8FAFC", textAlign: "center"}}>
      {/* Section Title */}
      <Typography
        variant="h3" sx={{ fontWeight: "bold", mb: 3, color: "#189AB4" }}
      >
        Got Questions? We’ve Got Answers
      </Typography>

      {/* FAQ Items */}
      <Box sx={{ maxWidth: "1200px", margin: "0 auto" }}>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={activeIndex === index}
            onChange={() => handleAccordionChange(index)}
            sx={{
              borderRadius: "16px",
              mb: 2,
              overflow: "hidden",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor: activeIndex === index ? "#189AB4" : "#EDEDED",
                color: activeIndex === index ? "#FFFFFF" : "#5A5A5A",
                fontWeight: "bold",
                borderRadius: "inherit",
                textAlign: "center", // Center align text
                display: "flex", // Flexbox for alignment
                justifyContent: "center",
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                  justifyContent: "center",
                },
              }}
            >
              {faq.question}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#5A5A5A",
                p: 2,
              }}
            >
              {faq.answer}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>



    <Box
      sx={{
        py: 4,
        px: 6,
        backgroundColor: "#0A435E", // Full-width background
        color: "#FFFFFF",
        width: "100%", // Ensures the background spans the full width
      }}
    >
      <Box
        sx={{
          maxWidth: { xs: "90%", md: "65%" }, 
          margin: "0 auto", // Centers the content horizontally
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          //flexWrap: "nowrap", // Prevents items from stacking
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
        }}
      >
        {/* Left Section */}
        <Box sx={{ flex: 1, minWidth: "200px", textAlign: { xs: "center", md: "left" } }}>
          <Typography
            variant="h6"
            sx={{ 
              fontWeight: "bold", 
              mb: 1, 
              color: "#74E6DC" 
            }}
          >
            Newsletter Sign Up
          </Typography>
          <Typography variant="body2" sx={{ lineHeight: 1.5 }}>
          Stay inspired and informed! Subscribe to our newsletter for exclusive tips, insights, and updates to fuel your growth journey.
          </Typography>
        </Box>

        {/* Input Section */}
        <Stack
          direction="row"
          spacing={2}
          sx={{
            flex: 2, // Larger flex to give more space to the input field
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Enter your email"
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "50px",
              borderColor: "#74E6DC",
              borderWidth: 2,
              width: "100%",
              maxWidth: "400px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
              },
            }}
          />
          <Button
            variant="contained"
            sx={{
              borderRadius: "50px",
              backgroundColor: "#74E6DC",
              color: "#0A435E",
              textTransform: "none",
              fontWeight: "bold",
              px: 4,
              "&:hover": {
                backgroundColor: "#5DC4B4",
              },
            }}
          >
            Sign Up
          </Button>
        </Stack>

        {/* Right Section */}
        <Box
          sx={{
            flex: 1,
            minWidth: "200px",
            textAlign: "center",
          }}
        >
          <Typography variant="body2" sx={{ lineHeight: 1.5 }}>
            You will receive our <strong style={{ color: "#FFFFFF" }}>FREE</strong>{" "}
            <br />
            <strong style={{ color: "#74E6DC" }}>BUDGETING TEMPLATE</strong> <br />
            for you and your business.
          </Typography>
        </Box>
      </Box>
    </Box>


    <Box sx={{ py: 6, textAlign: "center", width: "100%" }}>
      {/* Section Title */}
      <Typography
        variant="h3"
        sx={{ fontWeight: "bold", mb: 4, color: "#189AB4" }}
      >
        Latest Blog Posts
      </Typography>

      {/* Blog Posts */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 4,
          justifyContent: "center",
          maxWidth: "80%",
          margin: "0 auto",
        }}
      >
        {blogPosts.map((post) => (
          <Box
            key={post.id}
            sx={{
              flex: "1 1 calc(20% - 16px)", // Makes each card take 25% of the row
              minWidth: "300px", // Ensures a minimum width for smaller screens
              borderRadius: "16px",
              overflow: "hidden",
              border: "1px solid #189AB4",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Blog Card */}
            <Card
              sx={{
                borderRadius: "16px",
                overflow: "hidden",
                height: "100%",
              }}
            >
              {/* Blog Image */}
              <CardMedia
                component="img"
                height="150"
                image={post.image}
                alt={post.title}
                sx={{
                  borderRadius: "0px",
                  margin: 0
                }}
              />
              {/* Blog Content */}
              <CardContent sx={{ textAlign: "left", p: 2 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  {/* Date */}
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <CalendarTodayIcon sx={{ fontSize: "16px", color: "#A0A0A0" }} />
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "#A0A0A0" }}
                    >
                      {post.date}
                    </Typography>
                  </Box>
                  {/* Author */}
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <PersonIcon sx={{ fontSize: "16px", color: "#A0A0A0" }} />
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "#A0A0A0" }}
                    >
                      {post.author}
                    </Typography>
                  </Box>
                </Stack>
                {/* Blog Title */}
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", mt: 1, color: "#189AB4" }}
                >
                  {post.title}
                </Typography>
                {/* Blog Excerpt */}
                <Typography
                  variant="body2"
                  sx={{ mt: 1, color: "#5A5A5A", lineHeight: 1.6 }}
                >
                  {post.excerpt}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>

      {/* "See All" Button */}
      <Button
        variant="contained"
        fullWidth={false} // Adjusted for responsiveness
        sx={{
          mt: 4,
          px: { xs: 2, sm: 4 }, // Smaller padding for mobile
          py: { xs: 1.5, sm: 2 }, // Adjust vertical padding
          width: { xs: "80%", sm: "50%", md: "15%" }, // Responsive width
          backgroundColor: "#0A435E",
          color: "#74E6DC",
          borderRadius: "24px",
          textTransform: "none",
          fontSize: { xs: "16px", sm: "18px", md: "20px" }, // Adjust font size
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#189AB4",
            color: "#fff",
          },
        }}
      >
        See All
      </Button>
    </Box>

      <Footer/>
    </Box>
    </>
  );
};

export default VisitorsPage;